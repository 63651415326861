<template>
  <section class="SkilTest">
    <iq-card
      class="
        iq-card
        headerCardTitle
        p-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="title">
        <i class="ri-arrow-left-s-line mr-3" @click="$router.back(-1)"></i>
        Certificate details
      </div>
    </iq-card>
    <iq-card class="iq-card cardContent">
      <div>
        <div v-if="certificate.type == 'setmeal'">
          <div class="row">
            <p class="col-3">title:</p>
            <p class="col">{{ certificate.title }}</p>
          </div>
          <div class="row">
            <p class="col-3">introduce:</p>
            <p class="col">{{ certificate.introduce }}</p>
          </div>
          <div class="row">
            <span class="col-3">Certificate</span>
            <p class="col">
              <img class="w-25" :src="certificate.image" alt="" />
            </p>
          </div>
          <div class="row">
            <p class="col-3">Issue date:</p>
            <p class="col">{{ certificate.issue_date }}</p>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <el-button type="primary" @click="$router.back(-1)">Back</el-button>
          </div>
        </div>
        <div v-else>
          <!-- 证书基础信息 -->
          <div v-if="certificate.basic">
            <div class="row">
              <p class="col-3">title:</p>
              <p class="col">{{ certificate.basic.title }}</p>
            </div>
            <div class="row">
              <p class="col-3">introduce:</p>
              <p class="col">{{ certificate.basic.introduce }}</p>
            </div>
            <div class="row">
              <span class="col-3">Certificate</span>
              <p class="col">
                <img class="w-25" :src="certificate.basic.image" alt="" />
              </p>
            </div>
            <div
              class="row"
              v-if="
                certificate.certificate &&
                certificate.certificate.digital_certificate_template
              "
            >
              <span class="col-3">Digital certificate</span>
              <p class="col">
                <img
                  class="w-25"
                  :src="certificate.certificate.digital_certificate_template"
                  alt=""
                />
              </p>
            </div>
            <div
              v-if="
                (certificate.exam && certificate.exam.own == 'no') ||
                (certificate.sign_in && certificate.sign_in.own == 'no')
              "
            >
              <div v-if="certificate.basic.type == 2">
                <div class="row">
                  <p class="col-3">Examination:</p>
                  <p class="col">Yes</p>
                </div>
                <div class="row" v-if="certificate.paper.is_money == 1">
                  <p class="col-3">Examination type:</p>
                  <p class="col">Free</p>
                </div>
                <div v-else>
                  <div class="row">
                    <p class="col-3">Examination type:</p>
                    <p class="col">Purchase</p>
                  </div>
                  <div class="row">
                    <p class="col-3">Price:</p>
                    <p class="col">{{ certificate.paper.price }}</p>
                  </div>
                </div>
                <div class="row">
                  <p class="col-3">Total score:</p>
                  <p class="col">{{ certificate.paper.total_points }}</p>
                </div>
                <div class="row">
                  <p class="col-3">Examination time:</p>
                  <p class="col">{{ certificate.paper.time }} min</p>
                </div>
                <div class="row">
                  <p class="col-3">Passing grade:</p>
                  <p class="col">{{ certificate.paper.pass_mark }}</p>
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <el-button
                    type="primary"
                    v-if="certificate.paper.user_status == 'pay'"
                    @click="jumpPay(certificate.paper.id)"
                    >Pay</el-button
                  >
                  <el-button
                    type="primary"
                    v-if="
                      certificate.paper.user_status == 'exam' ||
                      certificate.paper.is_money == 1
                    "
                    @click="jumpTest()"
                    >Test</el-button
                  >
                </div>
              </div>
              <div v-else-if="certificate.basic.type == 1">
                <div class="row">
                  <p class="col-3">Qualified attendance:</p>
                  <p class="col">{{ certificate.sign_in.clock_number }}</p>
                </div>
                <div class="row">
                  <p class="col-3">Check work attendance:</p>
                  <p class="col">{{ certificate.sign_in.user_sign_on }}</p>
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <el-button type="primary" @click="$router.back(-1)"
                    >Back</el-button
                  >
                </div>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center mt-5">
              <el-button type="primary" @click="$router.back(-1)"
                >Back</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </iq-card>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      certificate: [],
    };
  },
  mounted() {
    this.courseCertificateInfo();
  },
  computed: {},
  methods: {
    // 跳转到考试页面
    jumpPay(id) {
      this.coursePaperPayPackage(
        id,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
    },
    jumpTest() {
      this.$router.push({
        path: "/certificate-test",
        query: { id: this.$route.query.id },
      });
    },
    coursePaperPayPackage(id, time) {
      this.$http
        .coursePaperPayPackage({ model_id: id, time_zone_name: time })
        .then((res) => {
          // if (res.status == 200) {
          //   console.log(res.data);
          //   if (res.data.is_jump == 1) {
          //     this.$router.push({
          //       path: `/PayMoney`,
          //       query: {
          //         type: 7,
          //         id: res.data.order_id,
          //         cType: res.data.type,
          //       },
          //     });
          //   } else if (res.data.is_jump == 0) {
          //     this.$router.push({
          //       path: `/PaySuccess`,
          //       query: {
          //         typeStatus: 1,
          //       },
          //     });
          //   }
          // }
        });
    },
    // 获取考试试卷详情
    courseCertificateInfo() {
      // console.log(this.$route.query.packagetype == 1 ? "single" : "setmeal");
      this.$http
        .courseCertificateInfo({
          id: this.$route.query.id, //证书id
          type: this.$route.query.packagetype == 1 ? "single" : "setmeal",
        })
        .then((res) => {
          if (res.status == 200) {
            this.certificate = res.data;
            // console.log(res.data);
          }
        });
    },
  },
};
</script>
<style src='' lang='scss' scoped>
.container {
  width: 960px;
  .SkilTest {
    .headerCardTitle {
      min-height: 65px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .timer {
        color: #ef5959;
      }
    }
    .cardContent {
      padding: 29px 56px 22px 41px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
      .options {
        li {
          min-width: 50%;
        }
      }
    }
  }
}
</style>
