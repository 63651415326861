var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"SkilTest"},[_c('iq-card',{staticClass:"\n      iq-card\n      headerCardTitle\n      p-3\n      d-flex\n      justify-content-between\n      align-items-center\n    "},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"ri-arrow-left-s-line mr-3",on:{"click":function($event){return _vm.$router.back(-1)}}}),_vm._v(" Certificate details ")])]),_c('iq-card',{staticClass:"iq-card cardContent"},[_c('div',[(_vm.certificate.type == 'setmeal')?_c('div',[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("title:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.title))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("introduce:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.introduce))])]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-3"},[_vm._v("Certificate")]),_c('p',{staticClass:"col"},[_c('img',{staticClass:"w-25",attrs:{"src":_vm.certificate.image,"alt":""}})])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Issue date:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.issue_date))])]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("Back")])],1)]):_c('div',[(_vm.certificate.basic)?_c('div',[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("title:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.basic.title))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("introduce:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.basic.introduce))])]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-3"},[_vm._v("Certificate")]),_c('p',{staticClass:"col"},[_c('img',{staticClass:"w-25",attrs:{"src":_vm.certificate.basic.image,"alt":""}})])]),(
              _vm.certificate.certificate &&
              _vm.certificate.certificate.digital_certificate_template
            )?_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-3"},[_vm._v("Digital certificate")]),_c('p',{staticClass:"col"},[_c('img',{staticClass:"w-25",attrs:{"src":_vm.certificate.certificate.digital_certificate_template,"alt":""}})])]):_vm._e(),(
              (_vm.certificate.exam && _vm.certificate.exam.own == 'no') ||
              (_vm.certificate.sign_in && _vm.certificate.sign_in.own == 'no')
            )?_c('div',[(_vm.certificate.basic.type == 2)?_c('div',[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Examination:")]),_c('p',{staticClass:"col"},[_vm._v("Yes")])]),(_vm.certificate.paper.is_money == 1)?_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Examination type:")]),_c('p',{staticClass:"col"},[_vm._v("Free")])]):_c('div',[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Examination type:")]),_c('p',{staticClass:"col"},[_vm._v("Purchase")])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Price:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.paper.price))])])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Total score:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.paper.total_points))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Examination time:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.paper.time)+" min")])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Passing grade:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.paper.pass_mark))])]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[(_vm.certificate.paper.user_status == 'pay')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.jumpPay(_vm.certificate.paper.id)}}},[_vm._v("Pay")]):_vm._e(),(
                    _vm.certificate.paper.user_status == 'exam' ||
                    _vm.certificate.paper.is_money == 1
                  )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.jumpTest()}}},[_vm._v("Test")]):_vm._e()],1)]):(_vm.certificate.basic.type == 1)?_c('div',[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Qualified attendance:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.sign_in.clock_number))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-3"},[_vm._v("Check work attendance:")]),_c('p',{staticClass:"col"},[_vm._v(_vm._s(_vm.certificate.sign_in.user_sign_on))])]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("Back")])],1)]):_vm._e()]):_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("Back")])],1)]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }